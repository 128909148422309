@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo:wght@300;500;900&display=swap');


html, body { 
  box-sizing: border-box;
  height: auto;
  overflow-x: hidden !important;
  width: 100% !important;
  padding: 0;
  margin: 0;
}


h1 {
  
  font-family: 'Exo', sans-serif;
  font-weight: 700;
  font-size: 30px;
}

h2 {
  font-family: 'Exo', sans-serif;
  font-size: 30px;
  font-weight: 700;
}

h3 {
  font-family: "Exo", sans-serif;
  font-size: min(4vw, 25px);
  font-weight: 300;
}

#subtext {
  font-family: "Play", sans-serif;
}

#blob {
  margin: 0 auto;
  overflow: visible;
  position: relative;
  z-index: 10;
  left: 150px;
  top: 150px;
  width: 100%;
  fill: #282a2b;
}



#child {
  position: relative;
  width: 80vw;
  

  
}

#textPage {
  opacity: 0;
  color: #fff;
}

/* mads svg */

.squiggle {
  stroke-dasharray: 3500;
  stroke-dashoffset: 3500;
  animation: draw 3s linear forwards;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

/* SLIDER CSS */
/* Bubble */

/*bubble*/

/*
const BubbleFlow = styled.div`

  opacity: 0.3;
  width: 300px;
  height: 300px;
  position:absolute;
  border: 1px solid rgba(255, 234, 178, 0.2);
  border-radius: 50%;
  background-image: 
  -webkit-radial-gradient(top right, circle cover,
  rgba(0, 0, 0, 0.3), rgba(0,0,0, 0.01));
  animation: move  15s alternate infinite, bubble 10s alternate-reverse infinite;

  &::before {
  content: "";
  position: absolute;
  height: 250px;
  width: 250px;
  top: 3%;
  left: 11%;
  border-radius: 50%;
  background-image: 
    -webkit-radial-gradient(80% 5%, circle,
     rgba(255, 255, 255, 0.5) 0%, rgba(255,255,255,0.1) 18%, rgba(255,255,255, 0)  26%);
  }
  
  &::after {
    content: "";
  position: absolute;
  height: 90px;
  width: 90px;
  top: 60%;
  right: 55%;
  border-radius: 50%;
  
  background-image: 
    -webkit-radial-gradient(16% 96%, circle,
     rgba(255, 234, 178, 0.3) 0%, rgba(255,234,178,0.1) 18%, rgba(255,255,255, 0)  26%);
  }


`;
*/

.bubble {
  opacity: 0.8;
  width: 300px;
  height: 300px;
  position:absolute;
  border: 1px solid rgba(255, 234, 178, 0.2);
  border-radius: 50%;
  background-image: 
  -webkit-radial-gradient(top right, circle cover,
   rgba(0, 0, 0, 0.3), rgba(0,0,0, 0.01));
   animation: move  15s alternate infinite, bubble 10s alternate-reverse infinite;
}

.bubble::before {
  /*opacity: 1;*/
content: "";
position: absolute;
height: 250px;
width: 250px;
top: 3%;
left: 11%;
border-radius: 50%;
background-image: 
  -webkit-radial-gradient(80% 5%, circle,
   rgba(255, 255, 255, 0.5) 0%, rgba(255,255,255,0.1) 18%, rgba(255,255,255, 0)  26%);
}

.bubble::after {
  /*opacity: 1;*/
  content: "";
position: absolute;
height: 90px;
width: 90px;
top: 60%;
right: 55%;
border-radius: 50%;

background-image: 
  -webkit-radial-gradient(16% 96%, circle,
   rgba(255, 234, 178, 0.3) 0%, rgba(255,234,178,0.1) 18%, rgba(255,255,255, 0)  26%);
}

@keyframes move {
  0% {
    transform: translate(-10px, 20px) rotate(0deg) scale(0.95, 0.97);
  }
  25% {
    transform: translate(-20px, -10px) scale(0.93, 0.95);
  }
  50% {
    transform: translate(10px, 20px) scale(0.94, 1);
  }
  75% {
    transform: translate(10px, 20px) scale(0.96, 0.98);
  }

  75% {
    transform: translate(10px, 20px) rotate(10d) scale(1);
  }
}

@keyframes bubble {
  0% {
border-radius: 54% 46% 56% 44% / 42% 53% 47% 58%;
  }



  100% {
    border-radius: 45% 55% 45% 55% / 51% 47% 53% 49%;
  }
}

.bubblePos {
  position: relative; 
}
.moveBubbleX {
  -webkit-animation: x 40s linear infinite alternate;
          animation: x 40s linear infinite alternate;
          width: 100%;
          height: 100%;
}

.moveBubbleY {
  -webkit-animation: y 20s linear infinite alternate;
          animation: y 20s linear infinite alternate;
}

@-webkit-keyframes x {
  100% {
    transform: translateX(calc(20vw - 290px));
  }
}

@keyframes x {
  100% {
    transform: translateX(calc(20vw - 290px));
  }
}
@-webkit-keyframes y {
  100% {
    transform: translateY(calc(25vh - 290px));
  }
}
@keyframes y {
  100% {
    transform: translateY(calc(25vh - 290px));
  }
}


#noUnderline {
  text-decoration: none !important;
}

/* BubsShadow*/

a, a:visited, a:active { color: yellow; text-decoration: none; font-weight: 700;}
a:hover {color: yellow;}
 