.nav .main-menu, .nav-toggle .nav-icon:before, .nav-toggle .nav-icon:after, main {
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.nav-toggle {
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
 
}

.nav-toggle {
  background-color: #333333;
  border-radius: 50%;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.23);
  cursor: pointer;
  height: 60px;
  position: fixed;
  right: 30px;
  bottom: 30px;
  transition: all 5650ms ease-in-out;
  width: 60px;
  z-index: 200;
}
.nav-toggle .nav-icon {
  background-color: #ffffff;
  bottom: auto;
  height: 2px;
  left: 50%;
  position: absolute;
  right: auto;
  transition: all 2300ms ease-in-out;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 30px;
}
.nav-toggle .nav-icon:before, .nav-toggle .nav-icon:after {
  content: "";
  background-color: inherit;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 2300ms ease-in-out;
  width: 100%;
}
.nav-toggle .nav-icon:before {
  transform-origin: right top;
  transform: translateY(-7px);
}
.nav-toggle .nav-icon:after {
  transform-origin: right bottom;
  transform: translateY(7px);
}
.no-touch .nav-toggle:hover {
  background-color: #595959;
  box-shadow: 0 3px 24px rgba(0, 0, 0, 0.3);
}
.no-touch .nav-toggle:hover .nav-icon:before {
  top: -2px;
}
.no-touch .nav-toggle:hover .nav-icon:after {
  top: 2px;
}
.nav-toggle:active, .no-touch .nav-toggle:active {
  background-color: gray;
}

.nav-open {
  width: 100vw !important;
  display: flex;
  justify-content: center;

}
.nav-open .nav-toggle {
  background-color: #3c3f50;
}
.nav-open .nav-toggle:hover {
  background-color: #5d617c;
}
.nav-open .nav-toggle:active {
  background-color: #8388a2;
}
.nav-open .nav-toggle:hover .nav-icon:before, .nav-open .nav-toggle:hover .nav-icon:after {
  top: 0;
}
.nav-open .nav-toggle .nav-icon {
  height: 3px;
  transform: translateX(-50%) rotate(180deg) scale(0.75);
}
.nav-open .nav-toggle .nav-icon:before, .nav-open .nav-toggle .nav-icon:after {
  width: 50%;
}
.nav-open .nav-toggle .nav-icon:before {
  transform: rotate(45deg);
}
.nav-open .nav-toggle .nav-icon:after {
  transform: rotate(-45deg);
}

.nav {
  
  background: #282a2b;
  height: 100vh;
  left: 0;
  padding: 0px 0px 0px;
  position: fixed;
  top: 0;
  transition: visibility 750ms;
  width: 100vw;
  visibility: hidden;
  z-index: 150 !important;
}
.nav .main-menu {
  height: 100vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  margin: 0px;
  transform: translateY(-175%);
  transition: transform 750ms;
  transition-timing-function: cubic-bezier(0.86, 0.01, 0.77, 0.78);
  font-size: 1.75rem;
  width: 100vw;
  margin-top: 100px;
  text-align: center;

  
}

.nav-open .nav {
  visibility: visible;
  transition: visibility 0;
}
.nav-open .nav .main-menu {
  transform: translateY(0);
  transition: transform 750ms;
  transition-timing-function: cubic-bezier(0.82, 0.01, 0.77, 0.78);
}

.main-menu h1 {
  color: yellow;
  font-size: 3rem;
  font-family: 'Exo';
}

p {
  color: #fff;
  font-size: 1.2rem;
  font-family: 'Exo';
}


/* test */
